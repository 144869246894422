import React from "react"

export interface LeftLogoProps {
  id?: string
}
export const LeftLogo = ({ id }: LeftLogoProps) => (
  <svg id={id} viewBox="0 0 100.699 42.884">
    <path d="M85.266 0c-.572 2.263-1.543 4.4-1.581 6.551-.17 9.645-.155 19.3-.012 28.94.033 2.188.841 4.365 1.306 6.617H65.402c.461-2.517 1.279-5.039 1.321-7.574q.238-14.177-.007-28.361c-.034-2.055-.937-4.1-1.448-6.173zM18.384 42.884c-.638-1.468-1.079-2.405-1.457-3.367C12.655 28.653 8.465 17.755 4.075 6.939 3.093 4.521 1.421 2.383 0 .006c5.45 0 11.361-.037 17.269.069.488.008 1.156.907 1.405 1.528q3.934 9.8 7.718 19.667a3.79 3.79 0 01.134 2.474c-2.286 5.942-4.677 11.844-7.057 17.749a9.135 9.135 0 01-1.085 1.391zM24.79.009c5.446 0 10.095-.041 16 .064.487.012 1.16.911 1.409 1.531q3.932 9.8 7.713 19.666a3.766 3.766 0 01.134 2.476c-2.285 5.944-4.675 11.841-7.053 17.751a9.3 9.3 0 01-1.084 1.386c-.638-1.467-1.079-2.4-1.461-3.364-3.526-8.977-7-17.972-10.578-26.926q-2.148-5.507-4.348-10.991zM62.675.009a.044.044 0 00-.012.023c-1.421 2.366-3.085 4.494-4.059 6.907q-1.5 3.688-2.969 7.4c-.227-.581-.458-1.166-.691-1.746q-2.149-5.506-4.355-10.989c-.25-.62-.673-1.577-.673-1.577C51.94.01 53.176.004 55.159.004c1.566 0 3.12 0 4.639.006zM87.941.009a.044.044 0 01.012.023c1.421 2.366 3.086 4.494 4.06 6.907q1.5 3.688 2.969 7.4c.226-.581.457-1.166.69-1.746q2.149-5.506 4.355-10.989c.249-.62.672-1.577.672-1.577-2.024-.017-3.26-.023-5.243-.023-1.566 0-3.12 0-4.639.006z" />
  </svg>
)
