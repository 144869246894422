import { Link as GatsbyLink } from "gatsby"
// import { useAdmin } from "hooks/use-admin"
import React, { CSSProperties, ReactNode } from "react"

export type RootPageID = "/"
export type PageID = "about" | "sap" | "course" | "team" | "contact"

export interface LinkProps {
  children?: ReactNode
  className?: string
  id?: string
  pageID?: RootPageID | PageID
  refId?: string
  style?: CSSProperties
}
/* eslint-disable jsx-a11y/anchor-is-valid */
export const Link = ({
  children,
  className,
  id,
  pageID,
  refId,
  style,
}: LinkProps) => {
  const path = !pageID ? "" : pageID === "/" ? pageID : `/${pageID}`
  const ref = refId ? "#" + refId : ""

  return (
    <GatsbyLink
      to={`${path}${ref}`}
      style={style}
      id={id}
      className={className}
      activeStyle={{ fontFamily: "Lao UI Bold" }}
      partiallyActive={true}
    >
      {children}
    </GatsbyLink>
  )
}
/* eslint-enable jsx-a11y/anchor-is-valid */
