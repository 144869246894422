import React from "react"

import { TOP_ID } from "../../get-in-touch/top-id"
import { Link } from "../../link"
import s from "./header.module.scss"
import { LeftLogo } from "./img/left-logo"
import { RightLogo } from "./img/right-logo"

export const Header = () => (
  <>
    <header className={`page-background-color ${s.header}`}>
      <nav>
        <Link pageID="/">
          <LeftLogo id={s.leftLogo} />
        </Link>
        <ul>
          <li>
            <Link pageID="about">About</Link>
          </li>
          <li>
            <Link pageID="sap">Sap</Link>
          </li>
          <li>
            <Link pageID="course">Course</Link>
          </li>
          <li>
            <Link pageID="team">Team</Link>
          </li>
          <li>
            <Link pageID="contact">Contact</Link>
          </li>
          <li id={s.rightLogo}>
            <RightLogo />
          </li>
        </ul>
      </nav>
    </header>
    <div id={TOP_ID} className={`page-background-color ${s.header}`} />
  </>
)
