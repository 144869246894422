import "./global-styles"

import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"

import { PageDataProps } from "../page"
import { Header } from "./header"

export interface LayoutProps extends PageDataProps {
  children: ReactNode
  color: "blue" | "yellow"
}
export const Layout = ({ children, pageTitle, color }: LayoutProps) => (
  <>
    <Helmet>
      <title>{pageTitle}</title>
      <body className={color} />
    </Helmet>
    <Header />
    {children}
  </>
)
